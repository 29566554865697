
.container{
    height: 100vh;
    padding-top: 100px;
    // position: fixed;
    // padding: 200px 50px 0px 50px;
}
// .login-padding{
//     padding: 0px 100px 0px 100px;
// }

// .card-login{
//     margin-top: -50px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border: none;
//     margin-left: 100px;
// }
