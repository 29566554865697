.sidebar {
  position:fixed;
  flex: 1;
  // border-right: 0.5px solid rgb(209, 209, 209);
  height: 100vh;
  // background:white;
  background-color: rgba(228, 225, 225, 0.08);
  // background-color: rgba(172, 169, 169, 0.08);
  // background-image:url('../../../public/images/plus.png') ;

  // .top {
  //   height: 50px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-top: 20px;
  //   margin-right: 20px;
  //   // padding: 60px 20px 30px 0px;

  //   .logo {
  //     font-size: 20px;
  //     font-weight: bold;
  //     color: #6439ff;
  //   }
  // }

  .logo img{
    width: 200px;
    margin-top: 10px;
  }

  hr {
    height: 0;
    border: 0px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      // border: 2px solid rgba(147, 147, 147, 0.08);
      // border-radius: 10px;
      // background-color: rgba(147, 147, 147, 0.08);

      .title {
        font-size: 16px;
        font-weight: bold;
        color: rgb(77, 75, 75);
        margin-top: 6px;
        margin-bottom: 0px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 3px;
          color: #7451f8;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #7451f8;
        }

        span{
            font-size: 14px;
            font-weight: 600;
            color:#888;
            margin-left: 10px;
        }
      }
    }
  }
}

.cms-icon{
  color: red;
}