@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
}

/* Headers */

.pageTitle {
  font-size: 24px;
  font-weight: 800;
  padding: 10px 0;
  color: #292929;
}

.wrapper {
  /* height: auto; */
  width: 100%;
  padding: 15px;
}

.accordion {
  width: 1000px;
}

.item {
  background: rgb(238, 238, 233);
  margin-bottom: 5px;
  padding: 2px 20px 2px 10px;
}

.faqTitle {
  color: rgb(85, 81, 81);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}

.content {
  color: rgb(0, 0, 0);
  max-height: 0;
  padding: 2px;
  margin-left: 8px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;

  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
input {
  width: 500px;
}

.containerform {
  padding: 15px;
}

.imageContainer {
  width: 600px;
  height: 300px;
  margin-left: 175px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
}

.sectionHolder {
  margin: 30px 0;
  background-color: rgb(245, 245, 245);
  padding: 20px;
}

.adultOnsetContainer {
  height: 50vh;
  padding: 30px;
}

.adultOnsetButton {
  text-align: center;
  margin-top: 100px;
}

.viewmore {
  color: rgb(34, 159, 241);
  border-bottom: 1px solid rgb(34, 159, 241);
}

.viewmore:hover {
  color: blue;
  border-bottom: 1px solid blue;
}
